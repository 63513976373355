import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const Quote = makeShortcode("Quote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "good-health-habits-for-good-mental-health"
    }}>{`Good health habits for good mental health`}</h1>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m7/32.svg" alt="friends hero image" mdxType="SingleImage" />
    <Quote quote="“A sound mind in a sound body.”" author="Thales, Greek philosopher" mdxType="Quote" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      